import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    DialogResetPasswordComponent
} from 'src/app/graphics/components/dialog-reset-password/dialog-reset-password.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Login} from '@store/action';
import {RoutePortalsName} from 'src/app/data/constants/route-portals.constants';
import {NpInstanceService} from 'src/app/data/services/np-instance.service';
import {LanguagesList} from 'src/app/data/constants/languages.constants';
import {selectErrorMessageAuthentication, selectLoadingAuthentication} from 'src/app/store/selector';
import {selectLanguage} from 'src/app/store/selector/language.selector';
import {LocalStorageService} from 'src/app/graphics/services/local-storage.service';
import {tap} from 'rxjs/operators';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {
    public currentUiLanguageView$ = this.store.select(selectLanguage)
        .pipe(tap(selectedLang => this.loginForm.controls.selectedLang.setValue(selectedLang)));

    public hidePassword = true;
    public returnUrl: string;
    public loading$ = this.store.select(selectLoadingAuthentication);
    public errorMessage$ = this.store.select(selectErrorMessageAuthentication);
    public loginForm: FormGroup;
    public logoUrl$ = this._instanceService.getLoginLogoUrl$();
    public instanceBg$ = this._instanceService.getBgImageInstance();

    public languages = LanguagesList;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        public _dialog: MatDialog,
        private fb: FormBuilder,
        private store: Store,
        private _instanceService: NpInstanceService,
        private _localStorageService: LocalStorageService
    ) {
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || `${RoutePortalsName.CONSULTATION}/${RoutePagesName.HOME}`;
        this.loginForm = this.fb.group({
            login: ['', Validators.required],
            password: ['', Validators.required],
            selectedLang: ['', Validators.required],
        });
    }

    onSubmit(): void {
        if (!this.loginForm.invalid) {
            this.store.dispatch(
                Login({
                    user: this.loginForm.value,
                })
            );

            this._localStorageService.saveUiLanguage(this.loginForm.controls.selectedLang.value);
        }
    }

    public openResetPasswordDialog() {
        return this._dialog.open(DialogResetPasswordComponent, {
            width: '500px',
            disableClose: true,
        });
    }
}
