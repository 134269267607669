import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {combineLatest, iif, Observable, of, Subscription} from 'rxjs';
import {map, mergeMap, shareReplay} from 'rxjs/operators';
import {environment} from '@env';
import {AdvSearchArgs, DicoCarac, NPElementType} from '@nextpage/np-sdk-data';
import {TABLE_HEADER_LABELS} from '@data/constants';
import {ParamsFilterBuilder} from '@data/builders';
import {Chars, SortType} from '@data/types';
import {MatSelectChange} from '@angular/material/select';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {NpInstanceService} from 'src/app/data/services/np-instance.service';
import {ExportBaseComponent} from 'src/app/graphics/components/base/export-base.component';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {ElementReaderService} from 'src/app/data/services/element-reader.service';
import {NPElement} from 'src/lib/data/model/np-element';
import {selectAdvancedSearchParamsScope, UpdateScope} from '@store/table';
import {selectChannelScopeExtId} from 'src/app/store/selector/user-info.selectors';
import {
  FetchProductsElements,
  FetchProductsElementsLoader,
  selectProductsElements
} from '@store/products';
import {selectDataLanguage} from 'src/app/store/data-languages';
import {Router} from '@angular/router';
import {FetchProductCharTemplates, selectProductCharTemplate} from '@store/product-char-template';
import {FilterInterface} from 'src/app/core/interfaces/filter-interface';
import {CharTemplateDto, ParamsFilter} from '@app/models';
import {
  CharTemplateFiltersComponent
} from 'src/app/graphics/components/char-template-filters/char-template-filters.component';
import { CONSULTATION_DEFAULT_PAGE_INDEX, CONSULTATION_DEFAULT_PAGE_SIZE } from 'src/app/data/constants/products-page-size.constants';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss'],
})
export class ProductsPageComponent extends ExportBaseComponent implements OnInit, OnDestroy, FilterInterface {
  public dataView$ = this._store.select(selectProductsElements)
      .pipe(shareReplay(1));

  public charTemplatesView$ = this._store.select(selectProductCharTemplate);
  private readonly _elementType: NPElementType = environment.instance.elementType;

  public defaultChannelScope: { scopeId: number | null; isChannel: boolean };
  public paramFilterBuilder: ParamsFilterBuilder;
  public selectedDataLanguageID: number;

  public channelCategoriesList$ = this._getChannelCategoriesList();
  public customClass: string;
  public toggle = true;
  public channelCategorySelected: NPElement;
  public pageSize = CONSULTATION_DEFAULT_PAGE_SIZE;
  public columnsToDisplay: string[];
  public readonly headerLabels = TABLE_HEADER_LABELS;

  private readonly elementType = environment.instance.elementType;

  private dataExportSub: Subscription;
  private _scopeIdSub: Subscription;

  @ViewChild('searchFilter') searchFilter!: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(CharTemplateFiltersComponent) charTemplateFiltersComponent: CharTemplateFiltersComponent;

  constructor(
      private _instanceService: NpInstanceService,
      private _elementReaderService: ElementReaderService,
      public router: Router
  ) {
    super();
    this.columnsToDisplay = ['overview', 'label', 'status', 'modificationDate', 'actions'];

    this.paramFilterBuilder = new ParamsFilterBuilder()
        .withNumberOfElementByPage(this.pageSize)
        .withElementTypes([this.elementType]);
  }

  ngOnInit(): void {
    this._store.dispatch(FetchProductCharTemplates());

    this.getElementsFromScope();

    this.customClass = this._instanceService.getStyleCass();

    this.searchFormControlSub = this.resetSearchField
        .subscribe(() => {
          this.searchByKeyword('');
        });
  }

  public sortData(params: Partial<SortType>) {
    this.paramFilterBuilder.withSortFields(params);

    this.dispatchSearchElements(null, this.paramFilterBuilder.build());
  }

  public searchByKeyword(searchedValue: string): void {
    const paramsFilter = this.paramFilterBuilder.withKeyword(searchedValue ?? '');
    if (this.channelCategorySelected) {
      paramsFilter.withChannelScope(this.channelCategorySelected.ID);
    }
    paramsFilter.withPage(CONSULTATION_DEFAULT_PAGE_INDEX);

    this.paginator?.firstPage();
    this.dispatchSearchElements(null, paramsFilter.build());
  }

  public resetSearch(event: Event): void {
    this.searchFilter.nativeElement.value = '';
    this.searchByKeyword(event.target['value']);
  }

  public handlePageEvent($event: PageEvent): void {
    const paramsFilter = this.paramFilterBuilder
      .withPage($event.pageIndex + 1)
      .withNumberOfElementByPage($event.pageSize)
      .build();

    this.dispatchSearchElements(null, paramsFilter);
  }

  public selectChannelCategory(event: MatSelectChange): void {
    this.channelCategorySelected = event.value;
    this.paginator?.firstPage();
    this.charTemplateFiltersComponent.resetAllSelectedFilters();

    this.resetProductFilters({isChannel: true, scopeId: this.channelCategorySelected.ID});

    this._store.dispatch(UpdateScope({
      scopeId: this.channelCategorySelected.ID,
      isChannel: true
    }));

    this.charTemplateFiltersComponent.filtersFacets([], null);

    this.dispatchSearchElements(null, this.paramFilterBuilder.build());
  }

  public clearChannelCategoriesFilter(): void {
    this.channelCategorySelected = null;
    this.paginator?.firstPage();

    this.charTemplateFiltersComponent.resetData(this.defaultChannelScope);

    this._store.dispatch(FetchProductsElementsLoader());
    this._store.dispatch(FetchProductsElements({ paramsFilter: this.paramFilterBuilder.build() }));

    this._store.dispatch(UpdateScope(this.defaultChannelScope));
  }

  public toggleView(change: MatButtonToggleChange) {
    this.toggle = change.value;
  }

  public exportAllProducts() {
    this.dataExportSub?.unsubscribe();

    this.dataExportSub = this.dataView$
      .pipe(
          // take(1),
          map((result) =>
              result?.productWithTotalRow ?
                  result.productWithTotalRow.productSummaries
                      .data
                      .map(productSummary => productSummary.element.ID) :
                  []
          ),
          mergeMap(elementIds => {
            return !_.isEmpty(elementIds) ? this.exportElements$(elementIds) : of(null);
          })
      )
      .subscribe(() => {
        this.dataExportSub.unsubscribe();
      });
  }

  private _getChannelCategoriesList(): Observable<NPElement[]> {
    return this._store.select(selectChannelScopeExtId)
      .pipe(
        mergeMap(channelExtId => {
          return iif(() =>
            !!channelExtId,
            this._elementReaderService.getDescendants(channelExtId, false, [DicoCarac.CHANNEL_NODE_LABEL, DicoCarac.CHANNEL_LABEL])
              .pipe(map((parent: NPElement) => parent?.Children || [])),
            of([])
          );
        }
        )
      );
  }

  private getElementsFromScope(): void {
    // Select scope (channel/channelNode/familyId)
    this._scopeIdSub = combineLatest([
      this._store.select(selectAdvancedSearchParamsScope),
      this._store.select(selectDataLanguage)
    ])
      .subscribe(([scope, selectedDataLang]) => {
        this.defaultChannelScope = scope;
        if (scope.selectedScope && scope.selectedScope.scopeId > 0) {
          this.paramFilterBuilder.withScope(scope.selectedScope);
          this.selectedDataLanguageID = selectedDataLang.ID;
          if (!!selectedDataLang) {
            this.paramFilterBuilder.withLangID(this.selectedDataLanguageID);
          }

          this.dispatchSearchElements(null, this.paramFilterBuilder.build());
        }
      });
  }

  dispatchSearchElements(advancedSearch?: AdvSearchArgs, paramsFilter?: ParamsFilter) {
    this._store.dispatch(FetchProductsElementsLoader());
    this._store.dispatch(FetchProductsElements({paramsFilter: paramsFilter}));
  }

  filterByProductType(productType: CharTemplateDto) {
    const paramsFilter = this.paramFilterBuilder
        .withProductTypeId(productType.ID)
        .withElementTypes([this._elementType])
        .withPage(CONSULTATION_DEFAULT_PAGE_INDEX)
        .build();

    this.paginator?.firstPage();
    this.dispatchSearchElements(null, paramsFilter);
  }

  resetProductFilters(scope?: { scopeId: number, isChannel: boolean }) {
    if (_.isNil(scope)) {
      scope = this.channelCategorySelected ? {
        isChannel: true,
        scopeId: this.channelCategorySelected.ID
      } : this.defaultChannelScope;
    }

    this.paramFilterBuilder
        .withScope(scope)
        .withChars(new Chars())
        .withProductTypeId(null)
        .withPage(CONSULTATION_DEFAULT_PAGE_INDEX);

    this.dispatchSearchElements(null, this.paramFilterBuilder.build());
  }

  searchProducts() {
    this.dispatchSearchElements(null, this.paramFilterBuilder.build());
  }

  ngOnDestroy(): void {
    this.dataExportSub?.unsubscribe();
    this._scopeIdSub?.unsubscribe();
    this.searchFormControlSub?.unsubscribe();

    this._store.dispatch(UpdateScope(this.defaultChannelScope));
  }
}
