import {inject, Injectable} from '@angular/core';
import {EntityParameter, EntityMenuItem} from '../models/np-entity.models';
import {Observable, of} from 'rxjs';
import {RouteResolverService} from '../services/routes/route-resolver.service';
import {RoutePortalsName} from '../constants/route-portals.constants';
import {NpInstanceService} from '../services/np-instance.service';
import { RoutePagesName } from '../constants/route-pages.constants';

@Injectable({
    providedIn: 'root'
})
export class EntityFacade {
    private _routeResolverService = inject(RouteResolverService);
    private _npInstanceService = inject(NpInstanceService);

    private _menuProductsItems: EntityMenuItem[] = [
        {
            associatedRouteName: RoutePagesName.HOME,
            label: 'enums.menus.home',
            iconName: 'home',
            selected: true,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.HOME)
        },
        {
            associatedRouteName: RoutePagesName.PRODUCTS,
            label: 'enums.menus.products',
            iconName: 'subject',
            selected: false,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.PRODUCTS)
        },
    ];

    private _menuMediasItems: EntityMenuItem[] = [
        {
            associatedRouteName: RoutePagesName.HOME,
            label: 'enums.menus.home',
            iconName: 'home',
            selected: true,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.HOME)
        },
        {
            associatedRouteName: RoutePagesName.MEDIAS,
            label: 'enums.menus.medias',
            iconName: 'perm_media',
            selected: false,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.MEDIAS)
        },
    ];
    private _menuSupplierItems: EntityMenuItem[] = [
        {
            associatedRouteName: RoutePagesName.HOME,
            label: 'enums.menus.home',
            iconName: 'home',
            selected: true,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.HOME)
        },
        {
            associatedRouteName: RoutePagesName.PRODUCTS,
            label: 'enums.menus.products',
            iconName: 'subject',
            selected: false,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.PRODUCTS)
        },
        {
            associatedRouteName: RoutePagesName.IMPORT,
            label: 'enums.menus.import',
            iconName: 'upload',
            selected: false,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.IMPORT)
        },
    ];
    private _menuPublicationItems: EntityMenuItem[] = [
        {
            associatedRouteName: RoutePortalsName.PUBLICATION,
            label: 'enums.menus.home',
            iconName: 'home',
            selected: true,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RoutePortalsName.PUBLICATION)
        }
    ];
    private _menuItemMap = new Map<RoutePortalsName, EntityMenuItem[]>([
            [RoutePortalsName.CONSULTATION, this._menuProductsItems],
            [RoutePortalsName.MEDIA_LIBRARY, this._menuMediasItems],
            [RoutePortalsName.SUPPLIER, this._menuSupplierItems],
            [RoutePortalsName.PUBLICATION, this._menuPublicationItems],
        ]
    );

    getEntityParameters(): Observable<EntityParameter> {
        return of({
            menuItems: this._menuItemMap.get(this._npInstanceService.currentEntity.routeName) || []
        });
    }
}
