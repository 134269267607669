import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {Field} from '@data/types';
import {ProductsFacade} from '@data/facades';
import {ProductSummary} from '@app/models';
import {LinkedProductItem} from '../../../data/models/media.model';
import {EntityName} from '../../../../lib/data/model/portal';
import {NpInstanceService} from '../../../data/services/np-instance.service';
import {DialogElementWriterComponent} from '../dialog-element-writer/dialog-element-writer.component';

@Component({
    selector: 'app-dialog-product-sheet',
    templateUrl: './dialog-product-sheet.component.html',
    styleUrls: ['./dialog-product-sheet.component.scss']
})
export class DialogProductSheetComponent implements OnInit, OnDestroy {
    public fields: Field[];
    public fields$: Subscription;
    public loading: boolean;
    public linkedElements: Map<string, LinkedProductItem[]>;
    public isSupplierEntityName: boolean;
    public logoUrl = this._instance.getLogoUrl();
    public currentDate = new Date();

    public imageByDefault$ =  this._instanceService.getImageByDefault();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ProductSummary,
        private productFacade: ProductsFacade,
        private dialogRef: MatDialogRef<DialogProductSheetComponent>,
        public dialog: MatDialog,
        private _instanceService: NpInstanceService,
        private _instance: NpInstanceService
    ) {
        this.isSupplierEntityName = this._instanceService.currentEntity.name === EntityName.Supplier;
    }

    ngOnInit(): void {
        this.loading = true;
        this.fields$ = this.productFacade
            .getInfoForProduct(this.data)
            .subscribe(result => {
                this.loading = false;
                this.fields = result.fields;

                // Si pas de visuel principal, récupère celui du parent (cas d'une Référence)
                if (!this.data.imageUrl) {
                    this.data.imageUrl = this.productFacade.getImageUrl(result.parent);
                }

                this.linkedElements = result.linkedElementsProducts;
            });
    }

    ngOnDestroy() {
        this.fields$?.unsubscribe();
    }

    print() {
        window.print();
    }

    editInfos(element: ProductSummary, $event: MouseEvent) {
        if (this.isSupplierEntityName) {
            $event.stopPropagation();
            this.dialog.open(DialogElementWriterComponent, {
                height: '95%',
                width: '80%',
                data: element
            });
            this.dialogRef.close();
        }
    }
}
