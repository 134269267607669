import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Store} from '@ngrx/store';
import {ElementSummary, ProductSummary} from 'src/app/data/models';
import {SearchDisplayFieldType, TABLE_HEADER_LABELS} from 'src/app/data/constants';
import {selectFavoritesExtIds} from 'src/app/store/selector';
import {FavoriteActions} from 'src/app/store/actions';
import {MatTableDataSource} from '@angular/material/table';
import {
    DialogElementWriterComponent
} from 'src/app/graphics/components/dialog-element-writer/dialog-element-writer.component';
import {EntityName} from 'src/lib/data/model/portal';
import {NpInstanceService} from 'src/app/data/services/np-instance.service';
import {MatSort, Sort} from '@angular/material/sort';
import {WorkflowRepository} from 'src/lib/data/services/workflow-repository';
import {ProductsFacade} from 'src/app/data/facades/products-facade';
import {SortType} from '@data/types';
import { DialogService } from 'src/app/graphics/services/dialog.service';


@Component({
    selector: 'app-product-table',
    templateUrl: './product-table.component.html',
    styleUrls: ['./product-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ProductTableComponent {
    @Input() columnsToDisplay: string[] = [];
    @Input() dataSource: MatTableDataSource<ProductSummary>;
    @Input() headerLabels = TABLE_HEADER_LABELS;

    @Output() public sortChange = new EventEmitter<Partial<SortType>>();

    @ViewChild(MatSort) sort: MatSort;

    public selection = new SelectionModel<ProductSummary>(true, []);
    public favoritesState$ = this.store.select(selectFavoritesExtIds);
    public isSupplierEntityName: boolean;
    public imageByDefault$ = this._instanceService.getImageByDefault();

    constructor(
        private store: Store,
        public dialog: MatDialog,
        private _instanceService: NpInstanceService,
        private _productsFacade: ProductsFacade,
        private _workflowRepository: WorkflowRepository,
        private readonly _dialogService: DialogService
    ) {
        this.isSupplierEntityName = this._instanceService.currentEntity.name === EntityName.Supplier;
    }

    public onSortChange(sortedColumn: Sort) {
        if (this.sort instanceof MatSort) {
            const caracId = this.assignFieldType(sortedColumn.active);
            this.sortChange.emit({
                FieldType: caracId,
                DicoCaracID: caracId,
                SortDesc: sortedColumn.direction === 'desc'
            });
        }
    }

    public assignFieldType(columnName:  string): SearchDisplayFieldType {
        switch (columnName) {
            case 'label': return SearchDisplayFieldType.Label;
            case 'modificationDate': return SearchDisplayFieldType.ModificationDate;
            case 'status': return SearchDisplayFieldType.WFStatus;
            default: return SearchDisplayFieldType.Label;
        }
    }

    public isSortableColumn(column: string): boolean {
        return column === 'label' || column === 'status' || column === 'modificationDate';
    }

    toggleFavorite(elementSummary: ElementSummary, $event: Event) {
        $event.stopPropagation();
        elementSummary.isFavoriteElement = !elementSummary.isFavoriteElement;
        this.store.dispatch(
            FavoriteActions.toggleFavorite({
                favorites: {
                    extID: elementSummary.element.ExtID
                },
            })
        );
    }

    showMoreInfos(element: ProductSummary, $event: Event) {
        $event.stopPropagation();
        this._dialogService.openProductDialog(element);
    }

    editInfos(element, $event: MouseEvent) {
        if (this.isSupplierEntityName) {
            $event.stopPropagation();
            this.dialog.open(DialogElementWriterComponent, {
                height: '95%',
                width: '80%',
                data: element
            });
        }
    }
}
