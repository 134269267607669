import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {RouteResolverService} from '../../../data/services/routes/route-resolver.service';
import { selectFavoritesCount } from 'src/app/store/selector';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';
import { FavoriteActions } from 'src/app/store/actions';

@Component({
  selector: 'app-favorite-selector',
  templateUrl: './favorite-selector.component.html',
  styleUrls: ['./favorite-selector.component.scss']
})
export class FavoriteSelectorComponent {
  favoritesState: Observable<number | string>;

  constructor(private _routeResolverService: RouteResolverService, private store: Store) {
    this.favoritesState = this.store.select(selectFavoritesCount);
  }

  goToFavorite() {
    this.store.dispatch(FavoriteActions.loadElementsFromFavoriteSuccess({favorites: []}));
    return this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.FAVORITE);
  }
}
