import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NPCaracValeur} from '@nextpage/np-sdk-data';
import {FileReaderService} from '../../services';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'lib-dialog-file-preview',
    templateUrl: './dialog-file-preview.component.html',
    styleUrls: ['./dialog-file-preview.component.scss']
})
export class DialogFilePreviewComponent implements OnInit {

    public currentMedia: NPCaracValeur;
    public fileSource: SafeResourceUrl;

    constructor(
        private _dialogRef: MatDialogRef<DialogFilePreviewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fileReader: FileReaderService,
        private _sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        if (this.data && this.data.currentMedia) {
            this.currentMedia = this.data.currentMedia;
            this.fileSource = this._sanitizer.bypassSecurityTrustResourceUrl(this._fileReader.toUrlCustomImageFromPath(this.currentMedia.Value));
        }
    }
}
