import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {RoutePortalsName, RouteParamName} from '../../constants/route-portals.constants';
import {NP_INSTANCE_CONFIG} from '../../constants/np-instance.constants';
import {NpInstanceService} from '../np-instance.service';

@Injectable({
    providedIn: 'root'
})
export class NpInstanceGuard implements CanActivate {
    constructor(private _router: Router, private _npInstanceService: NpInstanceService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const npInstanceId = route.paramMap.get(RouteParamName.INSTANCE_ID);
        if (npInstanceId && NP_INSTANCE_CONFIG.has(npInstanceId)) {
            this._npInstanceService.instanceId = npInstanceId;
            this._npInstanceService.instance = NP_INSTANCE_CONFIG.get(npInstanceId);
            return true;
        }
        this._router.navigate([`${RoutePortalsName.NO_INSTANCE_FOUND}`]);
        return false;
    }
}
