import {Injectable} from '@angular/core';

import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import {SearchMedia, SearchMediaFailure, SearchMediaSuccess} from '@store/action';
import {MediaFacade} from '@data/facades';
import {of} from 'rxjs';
import { Store } from '@ngrx/store';
import { selectMediaPreviewConfigs } from '../selector';
import * as _ from 'lodash';
import { AdvSearchArgs } from 'src/lib';

@Injectable()
export class MediaLibraryEffects {
    constructor(private actions$: Actions,
                private _store: Store,
                private _mediaFacade: MediaFacade) {
    }

    searchMedia$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SearchMedia),
            concatLatestFrom(() => [
                this._store.select(selectMediaPreviewConfigs)
                    .pipe(filter(result => !!result.mainVisualCaracExtId)),
            ]),
            mergeMap((
                [
                    action,
                    mediaPreviewParams
                ]) => {
                    const _advSearch = _.cloneDeep<AdvSearchArgs>(action.advancedSearch);
                    _advSearch.DCExtIDs = mediaPreviewParams.previewCaracs;

                    return this._mediaFacade.search(_advSearch, action.multiOperators)
                        .pipe(
                            map(searchResult => SearchMediaSuccess({searchResult}))
                        );
                }
            ),
            catchError(error => of(SearchMediaFailure({message: error?.toString()})))
        );
    });
}
