import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import {NpInstanceService} from '../np-instance.service';
import {RoutePortalsName} from '../../constants/route-portals.constants';

export const PageResolver: ResolveFn<RoutePortalsName> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    npInstanceService: NpInstanceService = inject(NpInstanceService)
): Observable<RoutePortalsName> => {
    if (route.url && route.url.length > 0 && route.url[0].path) {
        const currentPage = route.url[0].path as RoutePortalsName;
        npInstanceService.setCurrentInstancePage(currentPage);
        return of(currentPage);
    }
    return null;
};
