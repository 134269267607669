import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthenticationService} from '../../../lib';
import {GetUser, GetUserSuccess, Login, LoginFailure, LoginSuccess, Logout} from '@store/action';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {USER_TOKEN} from '@data/constants';
import {SpAuthenticationService} from '../../data/services/sp-authentication.service';
import {NpInstanceService} from '../../data/services/np-instance.service';
import {RouteResolverService} from '../../data/services/routes/route-resolver.service';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';

@Injectable()
export class AuthenticationEffects {
    constructor(private actions$: Actions,
                private authenticationService: AuthenticationService,
                private spAuthService: SpAuthenticationService,
                private router: Router,
                private _npInstanceService: NpInstanceService,
                private _routeResolverService: RouteResolverService) {
    }

    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(Login),
            mergeMap(action =>
                this.authenticationService.logIn(action.user.login, action.user.password).pipe(
                    map(result => LoginSuccess({result})),
                    catchError((error: string | HttpErrorResponse | Error) => {
                        let message;
                        if (typeof error === 'string') {
                            message = error;
                        } else {
                            message = error.message;
                        }
                        return of(LoginFailure({message}));
                    })
                )
            )
        );
    });

    logout$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(Logout),
                tap(() => {
                    this.spAuthService.isAuthenticated();
                })
            );
        },
        {dispatch: false}
    );

    loginSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LoginSuccess),
                tap(user => {
                    localStorage.setItem(USER_TOKEN, user.result);
                    return this._routeResolverService.navigateToDefaultEntityRoute(RoutePagesName.HOME);
                })
            );
        },
        {dispatch: false}
    );
    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetUser),
            mergeMap(() =>
                this.authenticationService.getCurrentUser().pipe(
                    map(result => GetUserSuccess({result})),
                    catchError((error: string | HttpErrorResponse | Error) => {
                        let message;
                        if (typeof error === 'string') {
                            message = error;
                        } else {
                            message = error.message;
                        }
                        return of(LoginFailure({message}));
                    })
                )
            )
        );
    });
}
