import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  DialogProductDeletionComponent
} from 'src/app/graphics/components/publication/dialog-product-deletion/dialog-product-deletion.component';
import {
  DialogProductsSelectionComponent
} from 'src/app/graphics/components/publication/dialog-products-selection/dialog-products-selection.component';
import { CatalogPage, Chapter, ProductWrapper } from '@app/models';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserCustomFieldValue } from '@store/selector';
import { SpDicoCarac } from 'src/app/data/constants';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import {convertProductWrappersToProducts} from '@np/utils';
import { UpdateChapter } from '@store/publication';

@Component({
  selector: 'app-catalogue-products',
  templateUrl: './catalogue-products.component.html',
  styleUrls: ['./catalogue-products.component.scss']
})
export class CatalogueProductsComponent {
  @Input() chapter: Chapter;

  private _page: CatalogPage;
  @Input()
  set page(value: CatalogPage) {
    this._page = value;
    this._page.rebuildProductWrappers();
  }
  get page(): CatalogPage {
    return this._page;
  }

  public descriptionField: string | Observable<never>;
  
  constructor(
    public dialog: MatDialog,
    private _store: Store) {
  }

  ngOnInit(): void {
    this._store.select(selectUserCustomFieldValue(SpDicoCarac.CP_EXTID_DESCRIPTIF_PUBLICATION))
      .pipe(
          map(value => value ? value : throwError('Custom field extID not found.'))
      ).subscribe(field => this.descriptionField = field);
  }

  public selectProduct(productWrapper?: ProductWrapper): void {
    this.dialog.open(DialogProductsSelectionComponent, {
      panelClass: 'products-selection-dialog',
      data: { chapter: this.chapter, descriptionField: this.descriptionField, page: this.page, element: productWrapper }
    }).afterClosed()
      .subscribe(() => {
        this.page.products = _.compact([...convertProductWrappersToProducts(this.page?.productsToAdd), this.page.memberProduct]);
        this._store.dispatch(UpdateChapter({chapter: this.chapter}));
      });
  }

  public confirmProductDeletion(item: ProductWrapper): void {
    this.dialog.open(DialogProductDeletionComponent, {
      panelClass: 'product-deletion-dialog',
      data: item
    }).afterClosed()
    .subscribe(confirmDeletion => {
      if (confirmDeletion
        && this.chapter?.pages
        && this.page) {
        _.remove(this.page.products, item.product);

        this._store.dispatch(UpdateChapter({chapter: this.chapter}));
        this.page.rebuildProductWrappers();
      }
    });
  }
}
