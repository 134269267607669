import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, EMPTY, throwError } from "rxjs";
import { selectDataLanguage } from "@store/data-languages";
import { selectElementPreviewConfigs, selectUserCustomFieldValue, selectPublicationMemberLogoExtId } from "@store/selector";
import { selectAllDicoCharacteristics } from "@store/characteristics";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { ElementRepository, NPElement } from "src/lib";
import { SpDicoCarac } from "@data/constants";
import { ProductsFacade } from "src/app/data/facades";
import { NpInstanceService } from "src/app/data/services/np-instance.service";
import * as _ from "lodash";

@Component({
    selector: 'app-logo-member',
    templateUrl: './logo-member.component.html',
    styleUrls: ['./logo-member.component.scss'],
})
export class LogoMemberComponent {

    public memberLogoUrl: string;

    constructor(
        private _store: Store,
        private _elementRepository: ElementRepository,
        private _productsFacade: ProductsFacade,
        private _instance: NpInstanceService) { }

    public ngOnInit(): void {
        this._store.select(selectUserCustomFieldValue(SpDicoCarac.CP_EXTID_ADHERENT))
        .pipe(
            switchMap(extId => {
                return this._store.select(selectPublicationMemberLogoExtId).pipe(
                    switchMap((logoExtId: string) => this._getElementByExtIds([extId as string], [logoExtId]).pipe(
                        map(result => {
                            const elements = Array.from(result.values());
                            elements.forEach((element: NPElement) => {
                                this.memberLogoUrl = this._productsFacade.getImageUrl(element, logoExtId);
                                if (!this.memberLogoUrl) {
                                    this.memberLogoUrl = this._instance.getLogoUrl();
                                }
                            });
                        })
                    ))
                );
            }),
            catchError(error => {
                console.error(error);
                return EMPTY;
            })
        ).subscribe();
    }

    private _getElementByExtIds = (elementExtIds: string[], otherPaths?: string[]) => {
        return combineLatest([
            this._store.select(selectDataLanguage),
            this._store.select(selectElementPreviewConfigs)
                .pipe(filter(result => !!result.mainVisualCaracExtId)),
            this._store.select(selectAllDicoCharacteristics)
        ]).pipe(
            switchMap(([selectedDataLang, previewParams, dicoCaracs]) => {
                const userDicoCaracs = previewParams?.previewCaracs.map(previewCarac => {
                    return dicoCaracs.find(dicoCarac => dicoCarac?.ExtID === previewCarac);
                });
                const characeristicsListOfLinkType = userDicoCaracs
                    .filter(characteristic => characteristic?.ExtID !== previewParams?.mainVisualCaracExtId && characteristic?.TypeCode.includes('LIEN'))
                    .map(characteristic => characteristic?.ExtID)
                    .map(element => [element, previewParams?.mainVisualCaracExtId]);
                characeristicsListOfLinkType.push([previewParams?.mainVisualCaracExtId]);
                const otherPathsCleaned = _.compact(otherPaths);
                if(otherPathsCleaned.length > 0) {
                    characeristicsListOfLinkType.push(otherPathsCleaned);
                }

                return this._elementRepository.getElements(
                    elementExtIds,
                    characeristicsListOfLinkType,
                    _.compact([...previewParams.previewCaracs, ...otherPathsCleaned]),
                    selectedDataLang?.ID
                );
            })
        );
    };
}
