import {Injectable} from '@angular/core';

import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {iif, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
    FetchProductsElements,
    FetchProductsElementsFailure,
    FetchProductsElementsSuccess
} from './products.actions';
import {ProductsFacade} from '@data/facades';
import {MessagesConstants} from '@data/constants';
import {selectPublicationElementPreviewConfigs} from '../selector/user-info.selectors';
import {ParamsFilter} from '@app/models';
import {selectStatus} from '../workflow-status';
import {ProductSummaryWithTotalRow} from 'src/app/data/types';
import {environment} from '@env';
import {NPElementType} from '@nextpage/np-sdk-data';
import * as _ from 'lodash';

@Injectable()
export class ProductsEffect {
    constructor(private actions$: Actions,
                private _productsFacade: ProductsFacade,
                private _store: Store,
                private _snackBar: MatSnackBar) {
    }

    fetchElements$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchProductsElements),
            concatLatestFrom(() => [
                this._store.select(selectPublicationElementPreviewConfigs),
                this._store.select(selectStatus)
            ]),
            mergeMap(([action, previewParams, workflowStatus]) => {
                const advancedSearchParam: ParamsFilter = action.paramsFilter;
                if(advancedSearchParam.fieldsToDisplay?.length> 0 ) {
                    // Combine the arrays
                    const combinedFields = advancedSearchParam.fieldsToDisplay.concat(previewParams.previewCaracs);
                    // Remove duplicates
                    advancedSearchParam.fieldsToDisplay = _.uniq(combinedFields);
                } else {
                    advancedSearchParam.fieldsToDisplay = previewParams.previewCaracs;
                }
                advancedSearchParam.mainVisualCaracExtId = previewParams.mainVisualCaracExtId;

                return this._productsFacade.FetchData(advancedSearchParam)
                    .pipe(
                        mergeMap((productSummariesResult: ProductSummaryWithTotalRow) => {
                            const productSummariesWithWorkflowStatusLabel = productSummariesResult.productSummaries.map(productSummary => {
                                const statusExtID = productSummary.element.getValueStatus()?.StatusExtID;
                                const status = workflowStatus.find(wfStatus => wfStatus.Name === statusExtID) || null;
                                return {...productSummary, status};
                            });

                            const updatedProductSummariesResult: ProductSummaryWithTotalRow = {
                                totalsRows: productSummariesResult.totalsRows,
                                productSummaries: productSummariesWithWorkflowStatusLabel
                            };

                            return iif(() => environment?.instance?.elementType === NPElementType.Reference,
                                this._fetchParentInfos(updatedProductSummariesResult),
                                of([])
                            )
                                .pipe(map(() => FetchProductsElementsSuccess({productSummariesResult: updatedProductSummariesResult})));

                        }),
                        catchError(error => {
                            this._snackBar.open(MessagesConstants.ELEMENTS_LOADING_ERROR);
                            return of(FetchProductsElementsFailure({message: error?.toString()}));
                        })
                    );
                }
            )
        );
    });

    /**
     * Récupère les parents (produits) des Références
     */
    private _fetchParentInfos(productSummariesResult: ProductSummaryWithTotalRow) {
        // Récupération des références qui n'ont pas la carac mainVisual
        const referencesWithoutMainVisual = productSummariesResult.productSummaries
            .filter(productSummary => _.isNil(productSummary.imageUrl) || _.isEmpty(productSummary.imageUrl));

        return iif(() => _.isEmpty(referencesWithoutMainVisual),
            of(true),
            this._productsFacade.assignMainVisualFromParent(referencesWithoutMainVisual) // récupération de mainVisual des parents (Produits)
        );
    }

}
