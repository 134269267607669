import {BreakpointObserver} from '@angular/cdk/layout';
import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {delay, filter, map, shareReplay} from 'rxjs/operators';
import {MatSidenav} from '@angular/material/sidenav';
import {NpInstanceService} from '../../../data/services/np-instance.service';
import {Store} from '@ngrx/store';
import {LanguageActions} from '../../../store/actions/language.action';
import { DataLanguageActions } from 'src/app/store/data-languages/data-language.action';
import { FetchWorkflowStatus } from 'src/app/store/workflow-status/workflow-status.actions';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { EntityName, ENTITY_NAME_MAP } from 'src/lib/data/model/portal';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';

@Component({
    selector: 'lib-portal-skeleton',
    templateUrl: './portal-skeleton.component.html',
    styleUrls: ['./portal-skeleton.component.scss'],
})
export class PortalSkeletonComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSidenav)
    sidenav?: MatSidenav;
    @Output() account = new EventEmitter<boolean>();
    @Output() logout = new EventEmitter<boolean>();

    public entities = Array
        .from(environment.instance.allowedEntities)
        .filter((entityName: EntityName) => {
            return environment.enableMediaLibrary ? true : entityName !== EntityName.MediaLibrary;
        })
        .map((entityName: EntityName) => ENTITY_NAME_MAP.get(entityName));

    public onlyPublicationPortal: boolean = false;

    public opened: boolean;

    public customClass: string;

    public isCataloguePage$ = this._instanceService.getCurrentInstancePage()
    .pipe(map(pageUrl  => this._instanceService.isPublicationPage() && pageUrl.page === RoutePagesName.CATALOGUE),shareReplay());
    public isHomepagePublicationPortal$ = this._instanceService.getCurrentInstancePage()
    .pipe(map(pageUrl  => this._instanceService.isPublicationPage() && pageUrl.page === RoutePagesName.HOME),shareReplay());

    constructor(
        private observer: BreakpointObserver,
        private router: Router,
        private _instanceService: NpInstanceService,
        private _store: Store
    ) {}

    ngOnInit(): void {
        this.customClass = this._instanceService.getStyleCass();
        this._store.dispatch(LanguageActions.fetchSelectedLanguage());
        this._store.dispatch(DataLanguageActions.fetchDataLanguages());
        this._store.dispatch(FetchWorkflowStatus());

        this.onlyPublicationPortal = (this.entities.length === 1 && this.entities[0].name === EntityName.Publication);
    }

    ngAfterViewInit() {
        this.observer
            .observe(['(max-width: 800px)'])
            .pipe(delay(1))
            .subscribe((response) => {
                if(!_.isNil(this.sidenav)) {
                    if (response.matches) {
                        this.sidenav.mode = 'over';
                        this.sidenav.close();
                    } else {
                        this.sidenav.mode = 'side';
                        this.sidenav.open();
                    }
                }
            });

        this.router.events
            .pipe(filter((e) => e instanceof NavigationEnd))
            .subscribe(() => {
                if (this.sidenav?.mode === 'over') {
                    this.sidenav.close();
                }
            });
    }

    goToAccount() {
        this.account.emit(true);
    }

    logoutEvent() {
        this.logout.emit(true);
    }
}
