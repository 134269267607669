import {createAction, props} from '@ngrx/store';
import {ProductSummaryWithTotalRow} from '@data/types';
import {ParamsFilter} from '@app/models';

export const FetchProductsElements = createAction('[Products Page] Fetch Elements',
    props<{paramsFilter: ParamsFilter}>());

export const FetchProductsElementsLoader = createAction('[Products Page]  Loader starting');

export const FetchProductsElementsSuccess = createAction('[Products Page] Fetch Elements Success', props<{
    productSummariesResult: ProductSummaryWithTotalRow
}>());

export const FetchProductsElementsFailure = createAction('[Products Page] Fetch Elements Failure', props<{
    message: string
}>());
