/***
 * Remove unauthorized characters (spaces, *, non numeric values...)
 */
export function removeUnauthorizedChars(thumbsSize: string): string {
    if (!!thumbsSize) {
        //If extension in format name
        let parts = thumbsSize.split('*');
        if (parts.length > 2) {
            return parts[0] + '*' + parts[1] + ' [' + parts.slice(2).join('*') + ']';
        }
        return thumbsSize;
    }
    return '';
}
