import {ParamsFilter} from '@app/models';
import {NPElementType} from '@nextpage/np-sdk-data';
import {Chars, SortType} from '../types';

export class ParamsFilterBuilder {
    private _numberOfElementByPage: number;
    private _page: number;
    private _keyword: string;
    private _elementTypes: NPElementType[];
    private _productTypeId: number;
    private _facetList: string[];
    private _chars: Chars;
    private _sortFields: Partial<SortType>;
    private _channelScope: number;
    private _familyScope: number;
    private _fieldsToDisplay: string[];
    private _mainVisualCaracExtId: string;
    private _langID: number;

    withNumberOfElementByPage(value: number): ParamsFilterBuilder {
        this._numberOfElementByPage = value;
        return this;
    }

    withPage(value: number): ParamsFilterBuilder {
        this._page = value;
        return this;
    }

    withKeyword(value: string): ParamsFilterBuilder {
        this._keyword = value;
        return this;
    }

    withElementTypes(value: NPElementType[]): ParamsFilterBuilder {
        this._elementTypes = value;
        return this;
    }

    withProductTypeId(value: number): ParamsFilterBuilder {
        this._productTypeId = value;
        return this;
    }

    withFacetList(value: string[]): ParamsFilterBuilder {
        this._facetList = value;
        return this;
    }

    withChars(value: Chars): ParamsFilterBuilder {
        this._chars = value;
        return this;
    }

    withSortFields(value: Partial<SortType>): ParamsFilterBuilder {
        this._sortFields = value;
        return this;
    }
    withChannelScope(value: number): ParamsFilterBuilder {
        this._channelScope = value;
        return this;
    }

    withScope(value: {isChannel: boolean, scopeId: number}): ParamsFilterBuilder {
        if (value?.isChannel) {
            this._channelScope = value?.scopeId;
            this._familyScope = null;
        } else {
            this._familyScope = value?.scopeId;
            this._channelScope = null;

        }
        return this;
    }

    withFamilyScope(value: number): ParamsFilterBuilder {
        this._familyScope = value;
        return this;
    }

    withLangID(value: number): ParamsFilterBuilder {
        this._langID = value;
        return this;
    }

    withFieldsToDisplay(value: string[]): ParamsFilterBuilder {
        this._fieldsToDisplay = value;
        return this;
    }

    withMainVisualCaracExtId(value: string): ParamsFilterBuilder {
        this._mainVisualCaracExtId = value;
        return this;
    }

    build(): ParamsFilter {
        return new ParamsFilter(
            this._numberOfElementByPage ?? 10,
            this._page ?? 1,
            this._keyword ?? '',
            this._elementTypes ?? [],
            this._productTypeId,
            this._facetList,
            this._chars,
            this._sortFields ? [this._sortFields as SortType] : [],
            this._channelScope,
            this._familyScope,
            this._fieldsToDisplay,
            this._mainVisualCaracExtId,
            this._langID ? this._langID : 1
        );
    }
}
