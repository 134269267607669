import {Injectable} from '@angular/core';

import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {FetchElements, FetchElementsFailure, FetchElementsSuccess} from './elements.actions';
import {ProductsFacade} from '@data/facades';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MessagesConstants} from '@data/constants';
import {selectElementPreviewConfigs} from '../selector/user-info.selectors';
import {ParamsFilter} from '@app/models';
import * as _ from 'lodash';
import {AdvancedSearchParamsState, selectAdvancedSearchParamsScope} from '../table';
import {selectDataLanguage} from '../data-languages';
import {selectStatus} from '../workflow-status';
import {ProductSummaryWithTotalRow} from 'src/app/data/types';

@Injectable()
export class ElementsEffect {
    constructor(private actions$: Actions,
                private _productsFacade: ProductsFacade,
                private _store: Store,
                private _snackBar: MatSnackBar) {
    }

    fetchElements$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchElements),
            concatLatestFrom(() => [
                this._store.select(selectElementPreviewConfigs)
                    .pipe(filter(result => !!result.mainVisualCaracExtId)),
                this._store.select(selectAdvancedSearchParamsScope)
                    .pipe(filter(result => !!result.scopeId)),
                this._store.select(selectDataLanguage),
                this._store.select(selectStatus)
            ]),
            mergeMap((
                    [
                        action,
                        elementPreviewParams,
                        scopeState,
                        selectedDataLang,
                        workflowStatus
                    ]) => {
                    const _paramsFilter = _.cloneDeep<ParamsFilter>(action.paramsFilter);
                    // Update main visual carac extId dans paths
                    _paramsFilter.mainVisualCaracExtId = elementPreviewParams.mainVisualCaracExtId;
                    // Update the scope (channel/channel node/family)
                    this._updateScope(_paramsFilter, scopeState);
                    // Update fields to display
                    _paramsFilter.fieldsToDisplay = elementPreviewParams.previewCaracs;

                    _paramsFilter.langID = selectedDataLang.ID;

                    return this._productsFacade.FetchData(_paramsFilter)
                        .pipe(
                            map((productSummariesResult: ProductSummaryWithTotalRow) => {
                                const productSummariesWithWorkflowStatusLabel = productSummariesResult.productSummaries.map(productSummary => {
                                    const statusExtID = productSummary.element.getValueStatus()?.StatusExtID;
                                    const status = workflowStatus.find(wfStatus => wfStatus.Name === statusExtID) || null;
                                    return { ...productSummary, status };
                                });

                                const updatedProductSummariesResult: ProductSummaryWithTotalRow = {
                                    totalsRows: productSummariesResult.totalsRows,
                                    productSummaries: productSummariesWithWorkflowStatusLabel
                                };

                                return FetchElementsSuccess({productSummariesResult: updatedProductSummariesResult});
                            }),
                            catchError(error => {
                                this._snackBar.open(MessagesConstants.ELEMENTS_LOADING_ERROR);
                                return of(FetchElementsFailure({message: error?.toString()}));
                            })
                        );
                }
            )
        );
    });

    private _updateScope(paramsFilter: ParamsFilter, state: AdvancedSearchParamsState) {
        if (state.isChannel) {
            paramsFilter.channelScope = state.scopeId;
            paramsFilter.familyScope = null;
        } else {
            paramsFilter.familyScope = state.scopeId;
            paramsFilter.channelScope = null;
        }
    }
}
