import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {map, shareReplay} from 'rxjs/operators';
import {RoutePagesName} from 'src/app/data/constants/route-pages.constants';
import {NpInstanceService} from 'src/app/data/services/np-instance.service';
import {FetchUserInfo} from 'src/app/store/actions';

@Component({
    selector: 'app-publication-main-page',
    templateUrl: './publication-main-page.component.html',
    styleUrls: ['./publication-main-page.component.scss'],
})
export class PublicationMainPageComponent implements OnInit {
    public isCataloguePage$ = this._instanceService.getCurrentInstancePage()
        .pipe(map(pageUrl => this._instanceService.isPublicationPage() && pageUrl.page === RoutePagesName.CATALOGUE), shareReplay());

    constructor(
        private _instanceService: NpInstanceService,
        private _store: Store
    ) {
    }

    ngOnInit(): void {
        this._store.dispatch(FetchUserInfo());
    }
}
