import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {ProductSummary} from '@app/models';
export const FavoriteActions = createActionGroup({
    source: 'favorite',
    events: {
        'load elements from favorite': emptyProps(),
        'load elements from favorite success': props<{ favorites: Partial<ProductSummary>[] }>(),
        'update favorites': props<{ favoriteExtIds: string[]}>(),
        'toggle favorite': props<{ favorites: Partial<ProductSummary> }>(),
        'load elements from favorite failure': props<{ message: string }>(),
        'count favorites': props<{ favoriteExtIds: string[] | null}>()
    },
});
