import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first, map, mergeMap, shareReplay, switchMap} from 'rxjs/operators';
import {WSParameters, WsParamsService} from './ws-params.service';
import {NpApiResult, NPDicoCarac} from '../model';
import {iif, Observable} from 'rxjs';
import {NpDicoCaracApiResult} from '../model/np-dico-carac-api-result';
import * as _ from 'lodash';
import {NpInstanceService} from '../../../app/data/services/np-instance.service';

@Injectable({
  providedIn: 'root',
})
export class DicocaracRepository {

  private _dicoCaracs$: Observable<NPDicoCarac[]> = null;
  private _dicoCaracsMin$: Observable<NPDicoCarac[]> = null;
  private _dicoCaracSync: NPDicoCarac[] = [];
  private _urlGetDicoCarac = '/api/sdk/dicocarac/GetAll/';
  private _urlGetMinDicoCarac = '/api/sdk/dicocarac/GetAllMin/';

  // TODO  IMPLEMENTS
  constructor(private _http: HttpClient,
              private _wsParamsService: WsParamsService,
              private _instanceService: NpInstanceService) {
    this._initialize();
    this._initializeMin();
  }


  private _initialize() {
    // initialisation des dicoCarac => la requete ne sera appelé que lorsqu'on s'en sert
    // on injecte les paramètres (langue) dans les paramètres url
    if (this._dicoCaracs$ == null) {
      this._dicoCaracs$ =
        this._wsParamsService.getParams().pipe(
          switchMap((params) => {
            return this._http.get<NpApiResult>(this.getURL(params));
          }),
          map((data: NpApiResult) => {
            // TODO: throw error
            if (data && data.Results && data.Results.hasOwnProperty('Caracs')) {
              this._dicoCaracSync = [];
              data.Results['Caracs'].forEach(d => this._dicoCaracSync.push(new NPDicoCarac(d)));
              return this._dicoCaracSync;
            }

          }),
          first(),
          shareReplay(1, 10000000)
        );
    }
  }
  private _initializeMin() {
    // initialisation des dicoCarac => la requete ne sera appelé que lorsqu'on s'en sert
    // on injecte les paramètres (langue) dans les paramètres url
    if (this._dicoCaracsMin$ == null) {
      this._dicoCaracsMin$ =
        this._wsParamsService.getParams().pipe(
          switchMap((params) => {
            return this._http.get<NpApiResult>(this.getMinURL(params));
          }),
          map((data: NpApiResult) => {
            // TODO: throw error
            if (data.Results != null && data.Results.hasOwnProperty('Caracs')) {
              this._dicoCaracSync = [];
              data.Results['Caracs'].forEach(d => this._dicoCaracSync.push(new NPDicoCarac(d)));
              return this._dicoCaracSync;
            }

          }),
          first(),
          shareReplay(1, 10000000)
        );
    }
  }

  getAll() {
    return this._dicoCaracs$;
  }

  getAllByLangId(langId: number) {
    return this._http.get<NpApiResult>(this.getURL({LangID: langId, ContextID: 0}))
        .pipe(
            map((data: NpApiResult) => {
              // TODO: throw error
              if (data && data.Results && data.Results.hasOwnProperty('Caracs')) {
                this._dicoCaracSync = [];
                data.Results['Caracs'].forEach(d => this._dicoCaracSync.push(new NPDicoCarac(d)));
                return this._dicoCaracSync;
              }

            }),
            first(),
            shareReplay(1, 10000000)
        );
  }

  getAllMin() {
    return this._dicoCaracsMin$;
  }


  getAllSync() {
    return this._dicoCaracSync;
  }

  getDicoCaracSync(ExtID: string): NPDicoCarac {
    return this._dicoCaracSync.find((value) => value.ExtID === ExtID);
  }

  getByExtIds(extIds: string[]) {
    return this._http.post<NpDicoCaracApiResult>('/api/sdk/dicocarac/GetByExtIds/', {
      DicoCaracExtIDs: extIds,
      LangId: 1,
      UserId: 0,
      NoSysCarac: false
    })
        .pipe(map(data => data?.Results?.Caracs || []));
  }

  getByExtId = (extId: string) => this.getByExtIds([extId])
      .pipe(map(result => _.first(result)))

  // A supprimer quand toutes les instance NP auront l'évolution sur GetByExtIds
  getByExtIdTest(extId: string) {
    return this._instanceService.getInstance$()
        .pipe(
            mergeMap(instance => {
                  return iif(() =>
                          instance.instanceId.includes('fermob'),
                      this.getByExtId(extId),
                      this.getDicoCarac(extId)
                  );
                }
            )
        );
  }

  getDicoCarac(ExtID: string): Observable<NPDicoCarac> {
    return this.getAll().pipe(
        map((data: NPDicoCarac[]) => {
          const arrResult = data.filter((value) => {
            return value.ExtID === ExtID;
          });
          if (arrResult.length === 1) {
            return arrResult[0];
          } else {
            return null;
          }
        })
    );
  }

  private getURL(params: WSParameters) {
    return this._urlGetDicoCarac + params.LangID;
  }

  private getMinURL(params: WSParameters) {
    return this._urlGetMinDicoCarac + params.LangID;
  }

  getDicoCaracs(ExtIDs: string[]) {
    return this.getAll().pipe(
      map((data: NPDicoCarac[]) => {
        return data.reduce((acc: Map<string, NPDicoCarac>, value: NPDicoCarac) => {
          if (ExtIDs.indexOf(value.ExtID) > -1) {
            acc.set(value.ExtID, value);
          }
          return acc;
        }, new Map<string, NPDicoCarac>());
      })
    );
  }


  getDicoCaracsAndReflexives(ExtIDs: string[]) {
    return this.getAll().pipe(
      map((data: NPDicoCarac[]) => {
        return data.reduce((acc: Map<string, NPDicoCarac>, value: NPDicoCarac) => {
          if (ExtIDs.indexOf(value.ExtID) > -1 || (value.ReflexiveCharExtID != null && ExtIDs.indexOf(value.ReflexiveCharExtID) > -1)) {
            acc.set(value.ExtID, value);
          }
          return acc;
        }, new Map<string, NPDicoCarac>());
      })
    );
  }
}
