import { createFeatureSelector, createSelector } from '@ngrx/store';
import { favoriteFeatureKey, FavoriteState } from '@store/reducers';

const selectState = createFeatureSelector<FavoriteState>(favoriteFeatureKey);

export const selectFavoritesList = createSelector(
    selectState,
    (state: FavoriteState) => state.favorites
);

export const selectFavoritesCount = createSelector(
    selectState,
    (state: FavoriteState) => state.favoriteExtIds?.length || ''
);

export const selectFavoritesExtIds = createSelector(
    selectState,
    (state: FavoriteState) => state.favoriteExtIds || []
);

export const selectFavoritesListLoader = createSelector(
    selectState,
    state => state.loading
);
