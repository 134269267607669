import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NpDownloadService} from '@nextpage/np-sdk-data';
import {ProductSummary} from '@app/models';
import {removeExtension} from '../../../core/functions/media.functions';
import {Field} from '@data/types';
import {ProductsFacade} from '@data/facades';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog-media-sheet',
  templateUrl: './dialog-media-sheet.component.html',
  styleUrls: ['./dialog-media-sheet.component.scss']
})
export class DialogMediaSheetComponent implements OnInit, OnDestroy {

  public fields: Field[];
  public loading: boolean;
  public elementLabel: string;

  private fields$: Subscription;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: ProductSummary,
      private _downloadService: NpDownloadService,
      private productFacade: ProductsFacade,
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.data?.label?.length > 50) {
      this.elementLabel = this.data.label.slice(0, 40) + '...';
    }
    this.fields$ = this.productFacade
        .getInfoForMedia(this.data)
        .subscribe(result => {
            this.loading = false;
            this.fields = result.fields;
        });
  }

  print() {
    window.print();
  }

  downLoad(fileUrl: string) {
    this._downloadService.downloadFile(fileUrl, removeExtension(this.data?.label));
  }

  ngOnDestroy(): void {
    this.fields$?.unsubscribe();
  }
}
