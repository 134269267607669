import { Component, OnInit } from '@angular/core';
import { FetchUserInfo } from '@store/action';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/graphics/components/base/base.component';
import { FavoriteActions } from 'src/app/store/actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent extends BaseComponent implements OnInit {
  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(FetchUserInfo());
    const storedFavorites = localStorage.getItem(environment.favoriteKey);
    const dataFromLocalStorage: string[] = JSON.parse(storedFavorites);
    this.store.dispatch(FavoriteActions.countFavorites({favoriteExtIds: dataFromLocalStorage}));
  }
}
